<template>
  <div class="Customers">
    <div class="container-fluid">
      <div class="row">
        <div class="col-3">
          <h1 class="Page__title">{{ $t('CUSTOMERS_TITLE') }}</h1>
        </div>
        <div class="col-9" v-if="!dealersError">
          <button class="Button Button__create" v-if="!isDealersFormOpened" @click="setFormOpened(true)">
            <Plus /> {{ $t('CUSTOMERS_NEW_USER') }}
          </button>
          <button class="Button Button__close" v-if="isDealersFormOpened" @click="setFormOpened(false)">
            <Close /> {{ $t('CUSTOMERS_CLOSE_PANEL') }}
          </button>
        </div>
      </div>
      <div class="CreateForm row" v-if="!dealersError" :class="{ 'CreateForm--opened mb-4': isDealersFormOpened }">
        <div class="col">
          <div class="CreateForm__form">
            <form autocomplete="off" novalidate @submit.prevent="submitDealer()">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="customerCodeField">{{ $t('CUSTOMERS_CODE_LABEL') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          errors.code ||
                          (submitError && submitError.attrNames && submitError.attrNames.includes('code'))
                      }"
                      id="customerCodeField"
                      v-model="dealersModel.code"
                      :placeholder="$t('CUSTOMERS_CODE_PLACEHOLDER')"
                    />
                    <div class="invalid-feedback" v-if="errors.code">
                      {{ $t(errors.code) }}
                    </div>
                    <div
                      class="invalid-feedback"
                      v-if="submitError && submitError.attrNames && submitError.attrNames.includes('code')"
                    >
                      {{ $t('CUSTOMERS_ERROR_CODE_UNIQUE') }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="customerNameField">{{ $t('CUSTOMERS_NAME_LABEL') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          errors.name ||
                          (submitError && submitError.attrNames && submitError.attrNames.includes('name'))
                      }"
                      id="customerNameField"
                      v-model="dealersModel.name"
                      :placeholder="$t('CUSTOMERS_NAME_PLACEHOLDER')"
                    />
                    <div class="invalid-feedback" v-if="errors.name">
                      {{ $t(errors.name) }}
                    </div>
                    <div
                      class="invalid-feedback"
                      v-if="submitError && submitError.attrNames && submitError.attrNames.includes('name')"
                    >
                      {{ $t('CUSTOMERS_ERROR_NAME_UNIQUE') }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="customerEmailField">{{ $t('CUSTOMERS_EMAIL_LABEL') }}</label>
                    <input
                      type="email"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          errors.email ||
                          (submitError && submitError.attrNames && submitError.attrNames.includes('email'))
                      }"
                      id="customerEmailField"
                      v-model="dealersModel.email"
                      :placeholder="$t('CUSTOMERS_EMAIL_PLACEHOLDER')"
                    />
                    <div class="invalid-feedback" v-if="errors.email">
                      {{ $t(errors.email) }}
                    </div>
                    <div
                      class="invalid-feedback"
                      v-if="submitError && submitError.attrNames && submitError.attrNames.includes('email')"
                    >
                      {{ $t('CUSTOMERS_ERROR_EMAIL_UNIQUE') }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="customerDiscountField">{{ $t('CUSTOMERS_DISCOUNT_LABEL') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      :class="{ 'is-invalid': errors.discountPercentage }"
                      id="customerDiscountField"
                      v-model="dealersModel.discountPercentage"
                      :placeholder="$t('CUSTOMERS_DISCOUNT_PLACEHOLDER')"
                    />
                    <div class="invalid-feedback">
                      {{ $t(errors.discountPercentage) }}
                    </div>
                  </div>
                </div>
                <div class="col-6" v-if="!dealersIsEditing">
                  <div class="form-group">
                    <label for="customerPasswordField">{{ $t('CUSTOMERS_PASSWORD_LABEL') }}</label>
                    <input
                      type="password"
                      class="form-control"
                      :class="{ 'is-invalid': errors.password }"
                      id="customerPasswordField"
                      v-model="dealersModel.password"
                      :placeholder="$t('CUSTOMERS_PASSWORD_PLACEHOLDER')"
                    />
                    <div class="invalid-feedback">
                      {{ $t(errors.password) }}
                    </div>
                  </div>
                </div>
                <div class="col-6" v-if="!dealersIsEditing">
                  <div class="form-group">
                    <label for="customerConfirmPasswordField">{{ $t('CUSTOMERS_CONFIRM_PASSWORD_LABEL') }}</label>
                    <input
                      type="password"
                      class="form-control"
                      :class="{ 'is-invalid': errors.passwordConfirm }"
                      id="customerConfirmPasswordField"
                      v-model="dealersModel.passwordConfirm"
                      :placeholder="$t('CUSTOMERS_CONFIRM_PASSWORD_PLACEHOLDER')"
                    />
                    <div class="invalid-feedback">
                      {{ $t(errors.passwordConfirm) }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="customerCompanyField">{{ $t('CUSTOMERS_COMPANY_LABEL') }}</label>
                    <input
                      type="text"
                      class="form-control cursor-disabled"
                      disabled
                      id="customerCompanyField"
                      :value="userCompany === 'USA' ? $t('CUSTOMERS_COMPANY_USA') : $t('CUSTOMERS_COMPANY_ITA')"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="customerLogoField">{{ $t('CUSTOMERS_LOGO_LABEL') }}</label>
                    <input
                      type="file"
                      ref="customerLogoInput"
                      @change="processLogo($event)"
                      class="form-control"
                      :class="{ 'is-invalid': errors.logo }"
                      id="customerLogoField"
                    />
                    <div class="invalid-feedback">
                      {{ $t(errors.logo) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  <button class="Button Button__create">
                    <span v-if="!dealersIsEditing">{{ $t('CUSTOMERS_CREATE_BTN') }}</span>
                    <span v-if="dealersIsEditing">{{ $t('CUSTOMERS_EDIT_BTN') }}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="Filters row">
        <div class="col">
          <div class="Filters__form">
            <div class="row">
              <div class="col">
                <h2><Magnify /> {{ $t('CUSTOMERS_FILTER_TITLE_SEARCH') }}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <form @submit.prevent="filterSearch()">
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col-10">
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('CUSTOMERS_FILTER_PLACEHOLDER_NAME')"
                            v-model="dealerName"
                          />
                        </div>
                        <div class="col-2">
                          <button class="Button Button__create Filters-submit cursor-pointer" type="submit">
                            {{ $t('ACTION_SEARCH') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="mt-4 Filters__set">
                  <div class="no-filter" v-if="noFiltersSet()">
                    {{ $t('CUSTOMERS_FILTER_RECAP_NO_FILTER') }}
                  </div>
                  <div v-else>
                    <span v-if="queryString.name">
                      {{ $t('CUSTOMERS_FILTER_RECAP_PREFIX') }} {{ $t('CUSTOMERS_FILTER_BY_NAME') }}:
                      {{ queryString.name }}
                    </span>
                    <span class="reset-filter cursor-pointer pl-3" @click="filterCancel(true)"> <Delete /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!dealersError">
        <div class="col-12 pt-3">
          <div class="text-right export-list-btn">
            <button class="Button Button__send Button__send--auto" @click="downloadList()">
              <CloudDownload /> {{ $t('ACTION_EXPORT') }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="Table">
            <ErrorMessage v-if="dealersError && !isDealersFetching" />
            <div v-if="!dealersError">
              <NoItemsFound v-if="dealersTotal === 0 && !isDealersFetching" />
              <div v-if="dealersTotal > 0">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div @click="setSorting('code')">
                          {{ $t('CUSTOMERS_CODE') }}
                          <span v-if="queryString.sortBy === 'code'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div @click="setSorting('name')">
                          {{ $t('CUSTOMERS_NAME') }}
                          <span v-if="queryString.sortBy === 'name'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div @click="setSorting('email')">
                          {{ $t('CUSTOMERS_EMAIL') }}
                          <span v-if="queryString.sortBy === 'email'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <!-- <th scope="col">
                        <div @click="setSorting('company')">
                          {{ $t('CUSTOMERS_COMPANY') }}
                          <span v-if="queryString.sortBy === 'company'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th> -->
                      <th scope="col">
                        <div @click="setSorting('discountPercentage')">
                          {{ $t('CUSTOMERS_DISCOUNT') }}
                          <span v-if="queryString.sortBy === 'discountPercentage'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col" class="cursor-default">
                        {{ $t('CUSTOMERS_LOGO') }}
                      </th>
                      <th scope="col" class="cursor-default">
                        B2C <span v-if="userCompany === 'ITA'">€</span><span v-if="userCompany === 'USA'">$</span>
                      </th>
                      <th scope="col" class="cursor-default">
                        {{ $t('CUSTOMERS_HIDE_DESCRIPTION_PRICE') }}
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="dealer in dealers"
                      :key="dealer.id"
                      :class="{ 'is-dealer-deleting': isDealerDeleting(dealer.id) }"
                    >
                      <td>{{ dealer.code }}</td>
                      <td>{{ dealer.name }}</td>
                      <td>{{ dealer.email }}</td>
                      <!-- <td>{{ $t('CUSTOMERS_COMPANY_' + dealer.company) }}</td> -->
                      <td>{{ dealer.discountPercentage }}</td>
                      <td>
                        <img v-if="dealer.logo" style="max-height: 40px;" :src="dealer.logo" />
                        <span v-if="!dealer.logo">-</span>
                      </td>
                      <td>
                        <toggle-button
                          @change="data => onChangeb2cPrice(dealer.id, data.value)"
                          class="pt-2"
                          :value="dealer.b2cPrice"
                          :color="{ checked: '#ccc', unchecked: '#ccc' }"
                          :switchColor="{ checked: '#28a745', unchecked: '#ed1c24' }"
                          :width="50"
                          :height="25"
                        />
                      </td>
                      <td>
                        <toggle-button
                          @change="data => onChangeHideDescriptionPrice(dealer.id, data.value)"
                          class="pt-2"
                          :value="dealer.hideDescrPrice"
                          :color="{ checked: '#ccc', unchecked: '#ccc' }"
                          :switchColor="{ checked: '#28a745', unchecked: '#ed1c24' }"
                          :width="50"
                          :height="25"
                        />
                      </td>
                      <td style="min-width:200px;">
                        <div v-if="!isDealerDeleting(dealer.id)">
                          <button class="Button Button__send" v-b-modal="'dealerStoreLinkModal' + dealer.id">
                            <LinkVariant />
                            <span class="Button__label d-none d-xl-inline-block">{{ $t('ACTION_LINK') }}</span>
                          </button>
                          <button class="Button Button__delete" @click="setDealerDeleting(dealer.id, true)">
                            <Delete />
                            <span class="Button__label d-none d-xl-inline-block">{{ $t('ACTION_DELETE') }}</span>
                          </button>
                          <button class="Button Button__edit" @click="setDealerEditing(dealer.id)">
                            <Pencil />
                            <span class="Button__label d-none d-xl-inline-block">{{ $t('ACTION_EDIT') }}</span>
                          </button>
                        </div>
                        <div v-if="isDealerDeleting(dealer.id)">
                          <div class="confirm">
                            {{ $t('DELETE_CONFIRM_QUESTION') }}
                            <br />
                            <button @click="deleteDealer(dealer.id)">{{ $t('YES') }}</button>
                            <button @click="setDealerDeleting(dealer.id, false)">{{ $t('NO') }}</button>
                          </div>
                        </div>
                        <b-modal
                          :id="'dealerStoreLinkModal' + dealer.id"
                          class="fullScreenModal"
                          size="lg"
                          centered
                          hide-header
                          hide-footer
                        >
                          <p class="mt-4">
                            <b>{{ $t('CUSTOMER_LANDING_LINK') }}</b>
                          </p>
                          <a
                            :href="landingLink(dealer.IDToken)"
                            target="_blank"
                            class="b2cLink"
                            rel="noopener noreferrer"
                          >
                            {{ landingLink(dealer.IDToken) }}
                          </a>
                          <p class="mt-5">
                            <b>{{ $t('CUSTOMER_CONFIGURATOR_LINK') }}</b>
                          </p>
                          <a
                            :href="configuratorLink(dealer.IDToken)"
                            target="_blank"
                            class="b2cLink"
                            rel="noopener noreferrer"
                          >
                            {{ configuratorLink(dealer.IDToken) }}
                          </a>
                        </b-modal>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-pagination
            v-if="dealersTotal > 0 && dealersTotal > queryString.limit"
            size="md"
            align="center"
            @input="setPagination"
            :total-rows="dealersTotal"
            v-model="queryString.page"
            :per-page="queryString.limit"
          />
        </div>
      </div>
    </div>
    <Loader v-if="isDealersFetching" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Plus from 'vue-material-design-icons/Plus.vue'
import Close from 'vue-material-design-icons/Close.vue'
import ChevronUp from 'vue-material-design-icons/ChevronUp.vue'
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue'
import Delete from 'vue-material-design-icons/Delete.vue'
import Pencil from 'vue-material-design-icons/Pencil.vue'
import LinkVariant from 'vue-material-design-icons/LinkVariant.vue'
import Magnify from 'vue-material-design-icons/Magnify.vue'
import CloudDownload from 'vue-material-design-icons/CloudDownload.vue'
import validator from 'validator'

import NoItemsFound from '@/components/NoItemsFound.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import Loader from '@/components/Loader.vue'
import FileDownload from '@/services/FileDownload'

export default {
  name: 'customers',
  components: {
    Plus,
    Close,
    ChevronUp,
    ChevronDown,
    Delete,
    Pencil,
    LinkVariant,
    Magnify,
    CloudDownload,
    NoItemsFound,
    ErrorMessage,
    Loader
  },
  computed: {
    ...mapGetters([
      'isDealersFetching',
      'isDealerDeleting',
      'isDealersFormOpened',
      'dealersDefaultParams',
      'dealers',
      'dealersModel',
      'dealersIsEditing',
      'dealersTotal',
      'dealersError',
      'submitError',
      'loggedUser',
      'isAdmin',
      'userCompany'
    ]),
    queryString() {
      const result = { ...this.dealersDefaultParams, ...this.$route.query }
      return {
        ...result,
        page: Number(result.page)
      }
    }
  },
  data() {
    return {
      errors: {},
      dealerName: ''
    }
  },
  methods: {
    setFormOpened(value) {
      this.$store.dispatch('DEALERS_SET_FORM_OPENED', value)
      if (value === false) {
        this.errors = {}
        this.resetLogoInput()
      }
    },
    setQueryString(value) {
      const query = {
        ...this.$route.query,
        ...value
      }
      this.$router.push({ query })
    },
    setSorting(field) {
      let dir = this.queryString.sortBy === field ? (this.queryString.sortDir === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
      this.setQueryString({ sortBy: field, sortDir: dir })
    },
    setPagination(page) {
      this.setQueryString({ page })
    },
    getDealersList(params) {
      this.$store.dispatch('DEALERS_GET_LIST', params)
    },
    setDealerDeleting(id, value) {
      this.$store.dispatch('DEALERS_SET_IS_DELETING', { id, value })
    },
    setDealerEditing(id) {
      this.resetLogoInput()
      this.errors = {}
      this.setFormOpened(true)
      this.$store.dispatch('DEALERS_GET_ITEM', id)
    },
    deleteDealer(id) {
      this.$store.dispatch('DEALERS_DELETE', id)
    },
    processLogo(event) {
      this.dealersModel.logo = event.target.files[0]
    },
    submitDealer() {
      this.errors = {}
      if (!this.dealersModel.code) {
        this.errors.code = 'CUSTOMERS_ERROR_CODE_REQUIRED'
      }
      if (!this.dealersModel.name) {
        this.errors.name = 'CUSTOMERS_ERROR_NAME_REQUIRED'
      }
      if (!this.dealersModel.email) {
        this.errors.email = 'CUSTOMERS_ERROR_EMAIL_REQUIRED'
      } else if (!validator.isEmail(this.dealersModel.email)) {
        this.errors.email = 'CUSTOMERS_ERROR_EMAIL_INVALID'
      }
      if (
        this.dealersModel.discountPercentage === '' ||
        this.dealersModel.discountPercentage === null ||
        this.dealersModel.discountPercentage === undefined
      ) {
        this.errors.discountPercentage = 'CUSTOMERS_ERROR_DISCOUNT_REQUIRED'
      } else if (
        !Number.isInteger(Number(this.dealersModel.discountPercentage)) ||
        Number(this.dealersModel.discountPercentage) < 0 ||
        Number(this.dealersModel.discountPercentage) > 100
      ) {
        this.errors.discountPercentage = 'CUSTOMERS_ERROR_DISCOUNT_INVALID'
      }
      // Check password only if is not editing
      if (!this.dealersIsEditing) {
        if (!this.dealersModel.password) {
          this.errors.password = 'CUSTOMERS_ERROR_PASSWORD_REQUIRED'
        }
        if (this.dealersModel.passwordConfirm !== this.dealersModel.password) {
          this.errors.passwordConfirm = 'CUSTOMERS_ERROR_CONFIRM_PASSWORD_INVALID'
        }
      }
      if (this.dealersModel.logo && this.dealersModel.logo.size / 1024 / 1024 > 0.5) {
        this.errors.logo = 'CUSTOMERS_ERROR_LOGO_SIZE'
      } else if (
        this.dealersModel.logo &&
        typeof this.dealersModel.logo === 'object' &&
        !['image/gif', 'image/jpeg', 'image/png'].includes(this.dealersModel.logo.type)
      ) {
        this.errors.logo = 'CUSTOMERS_ERROR_LOGO_MIME'
      }
      // If no errors -> submit form
      if (Object.keys(this.errors).length === 0) {
        this.dealersModel.company = this.userCompany
        if (this.dealersIsEditing) {
          this.$store.dispatch('DEALERS_EDIT', this.dealersModel)
        } else {
          this.$store.dispatch('DEALERS_CREATE', this.dealersModel)
        }
        this.resetLogoInput()
      }
    },
    resetLogoInput() {
      // Reset input file for logo
      const logoInput = this.$refs.customerLogoInput
      logoInput.type = 'text'
      logoInput.type = 'file'
    },
    landingLink(token) {
      return window.location.origin + this.$router.resolve({ name: 'B2C_landing', params: { token: token } }).href
    },
    configuratorLink(token) {
      return window.location.origin + this.$router.resolve({ name: 'B2C_configurator', params: { token: token } }).href
    },
    onChangeb2cPrice(id, value) {
      this.$store.dispatch('DEALERS_EDIT', { id, b2cPrice: value })
    },
    onChangeHideDescriptionPrice(id, value) {
      this.$store.dispatch('DEALERS_EDIT', { id, hideDescrPrice: value })
    },
    cleanQueryString(keys) {
      return Object.keys(this.$route.query).reduce((res, e) => {
        if (!keys.includes(e)) {
          res[e] = this.$route.query[e]
        }
        return res
      }, {})
    },
    noFiltersSet() {
      return !this.queryString.name
    },
    filterCancel(submit) {
      this.dealerName = ''
      if (submit) {
        this.$router.push({ query: this.cleanQueryString(['name']) })
      }
    },
    setFiltersFromQueryString() {
      this.filterCancel()
      if (this.queryString.name) {
        this.dealerName = this.queryString.name
      }
    },
    filterSearch() {
      var query = this.cleanQueryString(['name'])

      if (this.dealerName) {
        query.name = this.dealerName
      }

      this.$router.push({ query })
    },
    downloadList() {
      this.$store.dispatch('DEALERS_GET_EXPORT_LIST').then(data => {
        FileDownload(
          'Sparco-CustomEasy_Customers_' + this.userCompany + '.xlsx',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          data
        )
      })
    }
  },
  watch: {
    $route() {
      this.setFiltersFromQueryString()
      this.getDealersList(this.queryString)
    }
  },
  created() {
    this.setFiltersFromQueryString()
    this.getDealersList(this.queryString)
  },
  beforeDestroy() {
    this.setFormOpened(false)
  }
}
</script>

<style scoped lang="scss">
.Customers {
  padding-top: 30px;

  .CreateForm {
    overflow: hidden;
    max-height: 0;
    color: $c-white;
    background: $c-black;
    transition: max-height 0.4s;

    &--opened {
      max-height: 500px;
    }

    &__form {
      padding: 20px 0;

      .form-control {
        border-radius: 0;
        // border: 1px solid $c-white !important;
        &.is-invalid {
          border: 1px solid $c-error !important;
        }
      }
      .invalid-feedback {
        text-align: center;
      }
    }
  }

  .Filters {
    color: $c-white;
    background: $c-black;
    &__form {
      padding: 25px 15px;
      h2 {
        font-size: 24px;
        margin: 0 0 15px 0;
      }
    }
    &__filter-type-btn {
      border: none;
      border-left: 1px solid $c-blue;
      border-right: 1px solid $c-blue;
      font-size: 16px;
      cursor: pointer;
      border-radius: 0;
      background: $c-white;
      color: $c-blue;
      text-align: center;
      width: 100%;
      // width: 150px;
      // &.larger {
      //   width: 165px;
      // }
      padding: 5px 0;

      &.selected {
        background: $c-blue;
        color: $c-white;
      }
    }
    .left-separator {
      border-left: 1px solid $c-grey2;
    }
    .form-control {
      // width: 32% !important;
      display: inline-block;
      border-radius: 0;
      padding: 5px;
    }
    .Filters-submit {
      width: 100%;
      max-width: 100%;
      padding: 5px !important;
    }
    .sent-order-text {
      font-size: 14px;
    }
    &__set {
      color: $c-grey;
      .no-filter {
        font-style: italic;
      }
      .reset-filter {
        color: $c-error;
        opacity: 0.8;
      }
    }
  }

  .is-dealer-deleting {
    background: rgba($c-black, 0.7) !important;
    .confirm {
      text-align: left;
      color: $c-white !important;

      button {
        width: 50px;
        font-size: 18px;
        color: $c-white;
        padding: 0 10px;
        margin-right: 15px;
        background: transparent;
        border: 1px solid $c-white;
      }
    }
  }

  .Table {
    margin-top: 15px;
    &__row {
      position: relative;
    }

    &__delete-row {
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
    }
  }
}
</style>
